import React, { Component } from 'react'
import NavBar from "./NavBar"
import Downbar from "./Downbar"
import "../style.home.css"
import Main from './Main'

export default class Home extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        let width = window.innerWidth
        return (
            <div>
                <NavBar />
                <div style={width >= 1202 && width <= 1572 ? { paddingTop: "150px" } : { paddingTop: "125px" }}>
                    <Main />
                </div>
                <Downbar />
            </div>
        )
    }
}
