import React, { Component } from 'react';
import "../style.home.css";
import NavBar from "../Home/NavBar";
import Downbar from "../Home/Downbar";
import Main from "./Main";

export default class Techmastrateg extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        let width = window.innerWidth
        return (
            <div>
                <NavBar />
                <div style={width >= 1202 && width <= 1572 ? { paddingTop: "150px" } : { paddingTop: "125px" }}>
                    <Main />
                </div>
                <Downbar />
            </div>
        )
    }
}
