import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class NoMatch extends Component {
    render() {
        return (
            <div>
                <p>This page doesn't exist. <Link to="/"> Click this to return to home page </Link></p>
            </div>
        )
    }
}
