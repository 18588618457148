import React, {Component} from "react";
import newsPhoto1 from "../Images/news_photo4.jpg";
import newsPhoto2 from "../Images/news_photo2.png";
import newsPhoto3 from "../Images/news_photo3.jpg";
import banerLeft from "../Images/baner_left.jpg";
import banerLeftSmall from "../Images/baner_left_small.jpg";
import banerRight from "../Images/baner_right.jpg";
import banerRightSmall from "../Images/baner_right_small.jpg";
import banerDots from "../Images/baner_dots.png";
import BrLogo from "../Images/BrLogo.svg";
import TechmatstrategLogo from "../Images/TechmatstrategLogo.svg";
import TalkinThingsLogo from "../Images/TalkinThings.png";
import "../style.main.css";
import {Link} from "react-router-dom";

export default class Main extends Component {
    render() {
        let width = window.innerWidth;
        return (
            <div className="main" style={width > 1200 ? {} : {marginTop: -30}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                            <div className="row mt-0 mt-lg-5 mb-0 mb-lg-3">
                                <p
                                    style={
                                        width > 1200
                                            ? {color: "#001546", fontWeight: "bold", fontSize: 27}
                                            : {color: "#001546", fontWeight: "bold", fontSize: 23}
                                    }
                                >
                                    A global manufacturer of high-quality RFID and NFC tags and
                                    provider of end-to-end smart packaging solutions.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            {width > 1200 ? (
                                <img
                                    src={banerDots}
                                    alt=""
                                    style={width > 1200 ? {width: "125%"} : {width: "100%"}}
                                />
                            ) : null}
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div>
                    <div className="row my-0 my-lg-3">
                        <div className="mt-4 mt-lg-0 my-2 my-lg-0 col-lg-6">
                            <a href="https://rfid.talkinthings.com">
                                <img
                                    className="banerLeft"
                                    src={width > 1200 ? banerLeft : banerLeftSmall}
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="mt-4 mt-lg-0 my-2 my-lg-0 col-lg-6">
                            <a href="https://solutions.talkinthings.com">
                                <img
                                    className="banerRight"
                                    src={width > 1200 ? banerRight : banerRightSmall}
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12" id="news">
                                    <p
                                        style={
                                            width > 1200
                                                ? {color: "#001546", fontWeight: "bold", fontSize: 25}
                                                : {color: "#001546", fontWeight: "bold", fontSize: 23}
                                        }
                                    >
                                        News
                                    </p>
                                    <hr/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 my-4 my-lg-0">
                                    <img src={newsPhoto1} alt="" className="px-3" width="100%"/>
                                    <p
                                        className="mt-3 px-3"
                                        style={{color: "#001546", fontWeight: "bold"}}
                                    >
                                        Looking for “classic” 20 mm NFC tags?
                                    </p>
                                    <p className="mt-3 px-3">
                                        When facing the semiconductor shortage it’s good to have more alternatives to
                                        choose from. We offer the TT-ND20 tags with both NTAG213 and the ST25TN ICs.
                                        Both chips offer similar user memory, UID mirroring and counter.
                                    </p>
                                    <a href="https://rfid.talkinthings.com/rfid-revolution">
                                        <button
                                            className="mx-3 btn px-4 rounded-pill my-1 my-lg-3"
                                            style={{color: "white", backgroundColor: "#0198de"}}
                                        >
                                            <span style={{fontSize: 15}}>READ MORE</span>
                                        </button>
                                    </a>
                                </div>
                                <div className="col-lg-4 my-4 my-lg-0">
                                    <img src={newsPhoto2} alt="" className="px-3" width="100%"/>
                                    <p
                                        className="mt-3 px-3"
                                        style={{color: "#001546", fontWeight: "bold"}}
                                    >
                                        High performance RAIN RFID
                                    </p>
                                    <p className="mt-3 px-3">
                                        Tag performance is one of the key factors in selecting RAIN
                                        RFID tags for a given application. At Talkin’ Things a team
                                        of experienced RFID engineers ensures the tags we design and
                                        manufacture deliver the best results on various
                                        materials. Meet the latest additions to our catalog
                                    </p>
                                    <a href="https://rfid.talkinthings.com/uhf">
                                        <button
                                            className="mx-3 btn px-4 rounded-pill my-1 my-lg-3"
                                            style={{color: "white", backgroundColor: "#0198de"}}
                                        >
                                            <span style={{fontSize: 15}}>READ MORE</span>
                                        </button>
                                    </a>
                                </div>
                                <div className="col-lg-4 my-4 my-lg-0">
                                    <img src={newsPhoto3} alt="" className="px-3" width="100%"/>
                                    <p
                                        className="mt-3 px-3"
                                        style={{color: "#001546", fontWeight: "bold"}}
                                    >
                                        New Product Catalog
                                    </p>
                                    <p className="mt-3 px-3 ">
                                        As our portfolio is continuously growing, we have recently
                                        released a new version of our product catalog. Here you will
                                        find the latest additions to our RAIN RFID tags range with
                                        excellent performance and industry-adopted dimensions.
                                    </p>
                                    <a href="https://rfid.talkinthings.com/catalogue">
                                        <button
                                            className="mx-3 btn px-4 rounded-pill my-1 my-lg-3"
                                            style={{color: "white", backgroundColor: "#0198de"}}
                                        >
                                            <span style={{fontSize: 15}}>READ MORE</span>
                                        </button>
                                    </a>
                                </div>
                                <Link to="/techmastrateg">
                                    <div className="nbcr row pr-0 mt-5">
                                        <div className="col-12 col-sm-4 pt-4">
                                            <img
                                                src={BrLogo}
                                                alt=""
                                                className="w-100"
                                            />
                                        </div>
                                        <div className="col-12 col-sm-4 pt-4">
                                            <img
                                                src={TalkinThingsLogo}
                                                alt=""
                                                className="w-100"
                                            />
                                        </div>
                                        <div className="col-12 col-sm-4 pt-4">
                                            <img
                                                src={TechmatstrategLogo}
                                                alt=""
                                                className="w-100"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
            </div>
        );
    }
}
