import React, {Component} from "react";
import "../style.techmastrateg.css";
import BrLogo from "../Images/BrLogo.svg";
import TalkinThingsLogo from "../Images/TalkinThings.png";
import TechmatstrategLogo from "../Images/TechmatstrategLogo.svg";

export default class Main extends Component {
    render() {
        return (
            <div class='techmastrateg mx-auto'>
                <div className="row pr-0">
                    <div className="col-12 col-sm-4 pt-4">
                        <img
                            src={BrLogo}
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-12 col-sm-4 pt-4">
                        <img
                            src={TalkinThingsLogo}
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-12 col-sm-4 pt-4">
                        <img
                            src={TechmatstrategLogo}
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                </div>
                <span>
                 <strong>Techmastrateg Eukines</strong><br/><br/>
                 Project co-financed by the National Center for Research and Development<br/>
under the TECHMATSTRATEG III program “Modern material technologies”.
                 <ul className="p-3">
                     <li>Project under realisation from <strong>January 2021</strong></li>
                     <li className="pt-2">Project duration - <strong>36 months</strong></li>
                     <li className="pt-2">Project value <strong>9 929 395.86 PLN (1 553 298.00 PLN concerns part of the project implemented by
                         Talkin Things in consortium with other entities)</strong></li>
                     <li className="pt-2">Grant amount <strong>9 293 939.29 PLN</strong></li>
                     <li className="pt-2">Own contribution <strong>635 456.57 PLN</strong></li>
                 </ul>
                    <strong>Project title:</strong> Functional composite materials for printable sensors in telerehabilitation<br/><br/>

                 <strong>Project description:</strong> The aim of the project is development of novel composite materials deposited with printing technology which will be employed for manufacturing of sensors in orthopaedic, sports and cardiological rehabilitation. Product innovation will be achieved through R&D tasks planned for developing technology of materials for fabrication of two types of sensors - pressure and electrochemical. The former will be implemented in the form of shoe insoles for remote monitoring of orthopaedic rehabilitation process. The latter group will include lactates concentration and pH as well as auxiliary measurements (temperature and humidity). Application of these sensors, printed on flexible polymer films attached to the skin (so-called electronic tattoos) will be physical effort monitoring. Developed solutions will present a new quality in terms of conducting rehabilitation process thanks to small mass, high flexibility and wireless operation as well as low price. These attributes will allow monitoring of treatment of patients in the conditions of their undisturbed living, outside medical facility, at the same time adjusting recommendations to the current state and thus improving individual and system efficiency of the healthcare. Furthermore, with employment of printed electectronics technology, the final devices will be manufactured in large-scale processes, which will allow relatively low price and thereby make the solutions available for wide group of customers. Besides material technology development, the project includes also:<br/>
                  <ul className="p-3">
                    <li>Clinical tests for defining measurement methodology and key functional parameters of the developed materials</li>
                    <li className="pt-2">Development of electronic communication and powering systems for measurements</li>
                    <li className="pt-2">Scaling the technology for industrial, large-volume manufacturing</li>
                    <li className="pt-2">Tests and certification of biocompatibility, patent applications and preparation of implementation documentation
for the developed solutions.
</li>
                  </ul>
                </span>
            </div>);
    }
}
