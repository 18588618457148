import React, { Component } from 'react'
import { AiOutlineFacebook, AiOutlineTwitter, AiOutlineLinkedin } from "react-icons/ai";
import {Link} from "react-router-dom";

export default class Downbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactHover: false,
            inquiryHover: false
        };
    }

    changeHover() {
        this.setState({
            contactHover: !this.state.contactHover
        });
    }

    inquiryChangeHover() {
        this.setState({
            inquiryHover: !this.state.inquiryHover
        });
    }

    render() {

        let date = new Date()
        let year = date.getFullYear()

        return (
            <div className="container-fluid" style={{ backgroundColor: "#001546", color: "white", fontSize: 14 }}>
                <div className="row mt-4 py-4">
                    <div className="col-xl-3"></div>
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-xl-3">
                                <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>TALKIN' THINGS HQ</p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 300 }}>Nowy Swiat 2/200</p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 300 }}>00-497 Warsaw, Poland</p>
                                <br />
                                <p style={{ margin: 0, padding: 0 }}> <a style={{ color: "white", textDecoration: "none" }} href="mailto:inquiry@talkinthings.com">E:inquiry@talkinthings.com </a> </p>
                                <span style={{ textAlign: "center" }}>
                                    <a href="https://rfid.talkinthings.com/contact">
                                        <button className="btn mb-3 mb-xl-0 mt-3 rounded-pill px-5" style={this.state.contactHover === true ? {minWidth: "177px", backgroundColor: "#0198de", color: "white" } : {minWidth: "177px", color: "white", textAlign: "center", border: "1px solid white" }} onMouseEnter={() => this.changeHover()} onMouseLeave={() => this.changeHover()}>
                                            Contact us
                                        </button>
                                    </a>
                                </span>
                                <span className="d-block">
                                     <Link to="/inquiry">
                                        <button className="btn mb-3 mb-xl-0 mt-3 rounded-pill px-5" style={this.state.inquiryHover === true ? {minWidth: "177px", backgroundColor: "#0198de", color: "white" } : {minWidth: "177px", color: "white", textAlign: "center", border: "1px solid white" }} onMouseEnter={() => this.inquiryChangeHover()} onMouseLeave={() => this.inquiryChangeHover()}>
                                            Tenders
                                        </button>
                                     </Link>
                                </span>
                            </div>
                            <div className="col-xl-3">
                                <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>TALKIN' THINGS FAB</p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 300 }}>Poleczki 19</p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 300 }}>02-822 Warsaw, Poland</p>
                            </div>

                            <div className="col-xl-3">
                                <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>TALKIN' THINGS R&D Hub</p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 300 }}>Rudna Mala 47</p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 300 }}>36-060 Glogow Malopolski, Poland</p>
                            </div>
                            <div className="col-xl-3">
                                <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>About</p>
                                <p style={{ margin: 0, padding: 0, fontSize: 13, fontWeight: 300 }}>Founded in 2013, Talkin’ Things is a global manufacturer of high-quality standard and personalized RFID tags and a complete IoT ecosystem provider for connected products.</p>
                                <p className="mt-3"><a href="https://rfid.talkinthings.com/privacy-policy" style={{ fontSize: 14, color: "white", textDecoration: "none" }}>Privacy Policy</a></p>
                                <p className="mb-3 mb-xl-2" style={{ margin: 0, padding: 0 }}><a href="https://rfid.talkinthings.com/terms-of-service" style={{ fontSize: 14, color: "white", textDecoration: "none" }}>Terms of service</a></p>
                                <a style={{ color: "white" }} href="https://www.facebook.com/talkin.things/"><AiOutlineFacebook style={{ height: "25px", width: "25px" }} /></a>
                                <a style={{ color: "white" }} href="https://twitter.com/ThingsTalkin"><AiOutlineTwitter style={{ height: "25px", width: "25px" }} className="mx-3" /></a>
                                <a style={{ color: "white" }} href="https://www.linkedin.com/company/talkin'%E2%80%8B-things/"><AiOutlineLinkedin style={{ height: "25px", width: "25px" }} /></a>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <p style={{ fontSize: 12, color: "#028cd0" }}>&copy; {year} Talkin' Things Sp. z o.o. All rights reserved.</p>
                        </div>
                    </div>
                    <div className="col-xl-3"></div>
                </div>
            </div>
        )
    }
}
