import React, {Component} from 'react';
import TalkinThingsLogo from "../Images/TalkinThings.png";
import "../style.navbar.css";
import {GiHamburgerMenu} from "react-icons/gi";
import {Link} from "react-router-dom";
import EuFlag from "../Images/EuFlag.png";
import Flags from "../Images/Flags.png";

export default class NavBar extends Component {
    constructor() {
        super()
        this.state = {
            clickedBurger: false,
            section: "",
            scrollMove:false
        };
    }
    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = event => {
       this.setState({scrollMove:true});
    }
    render() {
        let pathname = window.location.pathname;
        let width = window.innerWidth;
        return (
            <div className="container-fluid" style={{position: "fixed", zIndex: 20}}>
                <div className="row py-3" style={{backgroundColor: "white"}}>
                    <div className="col-xl-5 col-2"></div>
                    <div className="col-xl-2 col-8" style={{textAlign: "center"}}>
                        <Link to="/">
                            <img src={TalkinThingsLogo} alt=""
                                 style={width > 1200 || width < 800 ? {width: "100%"} : {width: "50%"}}/>
                        </Link>
                    </div>
                    <div className="col-xl-5 col-2"></div>
                </div>

                {width > 1200 ? (
                    <div>
                        <div className="row align-items-center navbarr"
                             style={{backgroundColor: "#001546", textAlign: "center"}}>
                            <div className="col-xl-3"></div>
                            <div className="col-xl-6">
                                <div className="row py-2">
                                    <div className="col-2" style={{
                                        margin: "auto",
                                        padding: "auto",
                                        textAlign: "center",
                                        fontWeight: 300
                                    }}>
                                        <a className="home-link"
                                           style={{color: "#d9dde5", fontSize: 14, textDecoration: "none"}}
                                           href="https://rfid.talkinthings.com">RFID Tags
                                        </a>
                                    </div>
                                    <div className="col-4" style={{
                                        margin: "auto",
                                        padding: "auto",
                                        textAlign: "center",
                                        fontWeight: 300
                                    }}>
                                        <a style={{color: "#d9dde5", fontSize: 14, textDecoration: "none"}}
                                           href="https://solutions.talkinthings.com">
                                            Smart packaging solutions
                                        </a>
                                    </div>
                                    <div className="col-2" style={{
                                        margin: "auto",
                                        padding: "auto",
                                        textAlign: "center",
                                        fontWeight: 300
                                    }}>
                                        <a href="https://talkinthings.com#news"
                                           style={{color: "#d9dde5", fontSize: 14, textDecoration: "none"}}
                                        >News
                                        </a>
                                    </div>
                                    <div className="col-2"
                                         style={{margin: "auto", padding: "auto", textAlign: "left", fontWeight: 300}}>
                                        <a href="https://store.talkinthings.com/"
                                           style={{color: "#d9dde5", fontSize: 14, textDecoration: "none"}}>Buy
                                            online</a>
                                    </div>
                                    <div className="col-2"
                                         style={{margin: "auto", padding: "auto", textAlign: "left", fontWeight: 300}}>
                                        <a href="https://rfid.talkinthings.com/contact" className="contact-link"
                                           style={pathname !== "/contact" ? {
                                               color: "#d9dde5",
                                               fontSize: 14,
                                               textDecoration: "none"
                                           } : {color: "#0198de", fontSize: 14, textDecoration: "none"}}
                                           to="/contact">Contact us
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3">
                                <a href="https://solutions.talkinthings.com/european-funds"
                                   className="float-end float-xl-none">
                                    <img src={EuFlag} alt="" style={{maxWidth: 169}}/>
                                </a>
                            </div>
                        </div>
                        <div className="row align-items-center position-relative"
                             style={{textAlign: "center", zIndex: -1}}>
                            <div className="col-xl-3"></div>
                            <div className="col-xl-6"></div>
                            <div className="ps-0 col-xl-3">
                                <img src={Flags} alt="" className={"flags " + (this.state.scrollMove ? "hidden " : "")
                                }/>
                            </div>
                        </div>
                    </div>
                ) : null}
                {width <= 1200 ? (
                    <div>
                        {this.state.clickedBurger !== true ? (
                            <div className="navbar-work-phone p-2 mt-1" style={{
                                position: "absolute",
                                backgroundColor: "white",
                                opacity: 0.6,
                                left: 10,
                                top: 12
                            }}>
                                <GiHamburgerMenu onClick={() => this.setState({clickedBurger: true})}/>
                            </div>
                        ) : (
                            <div className="row" style={{
                                position: "absolute",
                                width: "100vh",
                                height: "100vh",
                                backgroundColor: "#001346",
                                opacity: 0.9,
                                color: "white",
                                zIndex: 20,
                                left: 10,
                                top: 12
                            }}>
                                {/*<GrClose onClick={() => this.setState({ clickedBurger: false })} color="white" size="30px" />*/}
                                <div className="col-12" style={{color: "#d9dde5"}}>
                                    <span style={{fontSize: 30, opacity: 0.9}}
                                          onClick={() => this.setState({clickedBurger: false})}>x</span>
                                </div>
                                <div className="col-12" style={{marginTop: -75}}>
                                    <a style={{color: "#d9dde5", fontSize: 25}}
                                       href="https://rfid.talkinthings.com">RFID Tags
                                    </a>
                                </div>
                                <div className="col-12" style={{marginTop: -100}}>
                                    <a style={{color: "#d9dde5", fontSize: 25}}
                                       href="https://solutions.talkinthings.com">Smart packaging solutions
                                    </a>
                                </div>
                                <div className="col-12" style={{marginTop: -100}}>
                                    <a style={{color: "#d9dde5", fontSize: 25}}
                                       href="https://talkinthings.com#news"
                                    >
                                        News
                                    </a>
                                </div>
                                <div className="col-12" style={{marginTop: -100}}>
                                    <a href="https://rfid.talkinthings.com/contact"
                                       style={{color: "#d9dde5", fontSize: 25}}>
                                        Contact us
                                    </a>
                                </div>
                            </div>)}
                    </div>
                ) : null
                }
            </div>
        )
    }
}
