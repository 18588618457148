import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "./Home/Home"
import NoMatch from './NoMatch/NoMatch'
import Tenders from "./Tenders/Tenders";
import Techmastrateg from "./Techmastrateg/Techmastrateg";

export default class App extends Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/inquiry" exact component={() => <Tenders />} />
                        <Route path="/techmastrateg" exact component={() => <Techmastrateg />} />
                        <Route path="*" component={NoMatch} />
                    </Switch>
                </Router>
            </div>
        )
    }
}
