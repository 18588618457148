import React, {Component} from "react";
import "../style.tenders.css";
import pdf1 from '../files/Z1_zapytanie.pdf';
import pdf2 from '../files/Z1_zal1.pdf';
import pdf3 from '../files/Z1_zal2.pdf';
import pdf4 from '../files/Z2_zapytanie.pdf';
import pdf5 from '../files/Z2_zal1.pdf';
import pdf6 from '../files/Z2_zal2.pdf';

export default class Main extends Component {
    render() {
        return (
            <div class='inquiry'>
                <h1>Zapytanie 1/2021</h1>
                <span class={'d-block pb-4'}>
                    Nazwa zamówienia: <b>Dostawa elementów elektronicznych i układów uruchomieniowych</b><br/><br/>

                Celem zamówienia jest <b>dostawa elementów elektronicznych i układów uruchomieniowych</b> w ramach projektu pod nazwą: „Funkcjonalne materiały kompozytowe do drukowalnych sensorów do telerehabilitacji”, realizowanego w ramach III konkursu TECHMATSTRATEG „Nowoczesne technologie materiałowe”, nr umowy TECHMATSTRATEG-III/0032/2019.<br/><br/>

                
                Zamawiający w ramach zamówienia oczekuje dostarczenia przez Oferenta elementów elektronicznych i układów uruchomieniowych.
                Elementy elektroniczne i układy uruchomieniowe muszą być pierwszej jakości, fabrycznie nowe (nie może być używany przed dniem dostawy), wolne od wad technicznych i prawnych oraz nie mogą pochodzić z ekspozycji.<br/><br/>

                Zapraszamy do zapoznania się z załączonymi dokumentami<br/><br/>
                <a href={pdf1} target="_blank">Zapytanie ofertowe</a><br/><br/>
                <a href={pdf2} target="_blank">Formularz Ofertowy</a><br/><br/>
                <a href={pdf3} target="_blank">Oświadczenie o braku powiązań osobowych i kapitałowych</a><br/><br/>
            </span>
                <h1>Zapytanie nr 2/2021</h1>
                <span>
                    Nazwa zamówienia: <b>Dostawa laminatu PCB i podłoża elastycznego do wykonywania układów elektronicznych i anten</b><br/><br/>

Celem zamówienia jest <b>dostawa laminatu PCB i podłoża elastycznego do wykonywania układów elektronicznych i anten</b> w ramach projektu pod nazwą: „Funkcjonalne materiały kompozytowe do drukowalnych sensorów do telerehabilitacji”, realizowanego w ramach III konkursu TECHMATSTRATEG „Nowoczesne technologie materiałowe”, nr umowy TECHMATSTRATEG-III/0032/2019.<br/><br/>

Zamawiający w ramach zamówienia oczekuje dostarczenia przez Oferenta laminatu PCB i podłoża elastycznego do wykonywania układów elektronicznych i anten.<br/><br/>
Laminat PCB i podłoże elastyczne muszą być pierwszej jakości, fabrycznie nowe (nie może być używany przed dniem dostawy), wolne od wad technicznych i prawnych oraz nie mogą pochodzić z ekspozycji.<br/><br/>

                Zapraszamy do zapoznania się z załączonymi dokumentami<br/><br/>
                <a href={pdf4} target="_blank">Zapytanie ofertowe</a><br/><br/>
                <a href={pdf5} target="_blank">Formularz Ofertowy</a><br/><br/>
                <a href={pdf6} target="_blank">Oświadczenie o braku powiązań osobowych i kapitałowych</a><br/><br/>
            </span>
            </div>);
    }
}
